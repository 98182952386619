/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import StyledModalChangeAddress from "./VerifyModalAddress.styled";
import LocationSearchInput from "../LocationSearchInput/LocationSearchInput";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import {cartActions, checkout, locationActions} from '../../actions/index';
import { connect } from "react-redux";
import LoadingEllipsis from "../LoadingEllipsis/LoadingEllipsis";
import SVGIcon from "../utility/SVGIcon";
import {useWindowSize} from "../../hooks/useWindowSize";
import { addressPT } from "../../config/prop-types";
import {ThemePramsContext} from "../../App";
import {ReactSVG} from "react-svg";
import {history} from "../../helpers";
import {isKiosk, storeType} from "../../config/constants";
import InputAuthorization from "../forms/InputAuthorization/InputAuthorization";
import Tooltip from "../Tooltip/Tooltip";
import CartItem from "./CartItem/CartItem";
import { createPortal } from 'react-dom';
import ButtonOutline from "../ui/ButtonOutline";

let zipCode = null;

const VerifyModalAddress = props => {
    const { dispatch, address, isShown, onChange, locationPhone, brandLogo, pickupLocations, curbsideLocations, shippingLocations, changeAddressModal, cart} = props;
    const { width } = useWindowSize();
    const [terminalId, setTerminalId] = useState('');
    const [blog, setBlog] = useState(false)
    const [locationInfo, setLocationInfo] = useState('');

    const [deliveryLocation, setDeliveryLocation] = useState({
        address: "",
        lat: "",
        lng: "",
        zip: "",
        delivery_type: 'delivery'
    });
    const [shippingLocationInfo, setShippingLocationInfo] = useState({
        address: "",
        lat: "",
        lng: "",
        zip: "",
        delivery_type: 'shipping'
    });
    const [pickupLocationInfo, setPickupLocationInfo] = useState({
        address: "",
        lat: "",
        lng: "",
        zip: "",
        locationName: '',
        delivery_type: 'is_pickup',
    });
    const [curbsideLocationInfo, setCurbsideLocationInfo] = useState({
        address: "",
        lat: "",
        lng: "",
        zip: "",
        locationName: '',
        delivery_type: 'is_curbside'
    });
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [deliveryStatus, setDeliveryStatus] = useState('');
    const [errorMessage, setErrorMessage] = useState(false);
    const [isDelivery, setIsDelivery] = useState(false);
    const [isAddress, setIsAddress] = useState(true);
    const [deliveryType, changeDeliveryType] = useState("shipping");
    const isCalledRef = React.useRef(false);

    useEffect(() => {
        if(locationPhone){
            setLocationInfo(locationPhone)
        }
    }, [locationPhone])
    useEffect(() => {
        if(address && locationInfo && Object.keys(locationInfo).length && !isCalledRef.current){
            isCalledRef.current = true;
            // changeDeliveryType(address.is_pickup && locationInfo.is_store_pickup ? "pickup" : "delivery")
            if(address.delivery_type === "is_curbside"){
                const data = modifyCurbsideLocation(address)
                changeDeliveryType( "curbside")
                setCurbsideLocationInfo(data);
            } else if(address.delivery_type === "is_pickup"){
                const data = modifyPickupLocation(address)
                changeDeliveryType("pickup")
                setPickupLocationInfo(data);
                isCalledRef.current = true;
            } else if(address.delivery_type === "shipping"){
                const data = modifyShippingLocation(address)
                changeDeliveryType("shipping")
                setShippingLocationInfo(data);
                isCalledRef.current = true;
            }

        }

    },[address, shippingLocations, pickupLocations, curbsideLocations])

    useEffect(() => {
        dispatch(locationActions.getPickupLocations())
        dispatch(locationActions.getCurbsideLocations());
        dispatch(locationActions.getShippingLocations());
    },[])
    useEffect(() => {
        const appContainer = document.querySelector(".App")
        if(isShown){
            document.body.classList.add("no-scroll");

            if(appContainer) {
                appContainer.classList.add("has-blur");
            }
        }
        return ()=>{
            document.body.classList.remove("no-scroll");
            appContainer.classList.remove("has-blur");
        }
    }, [isShown]);
    useEffect(() => {
        if(width <= 768 && isShown) {
            document.body.classList.add("no-scroll");
        } else if(width > 768 && isShown) {
            document.body.classList.add("has-scroll");
        }
        return () => {
            document.body.classList.remove("no-scroll");
        }
    }, [width, isShown]);

    useEffect(() => {
        if(deliveryStatus === "notDelivery") {
            setIsDelivery(true)
        } else {
            setIsDelivery(false)
        }
    }, [deliveryStatus]);

    useEffect(() => {
        dispatch(locationActions.selectedLocation());
    }, []);

    useEffect(() => {
        if(address && address.address && address.delivery_type === 'delivery'){
            const data = {
                ...deliveryLocation,
                address: address.address,
                lat: address.lat,
                lng: address?.lng ? address?.lng : address?.lon,
                zip: address.zip,
            };
            setDeliveryLocation(data);
            handleOnSelect(address.address, address.delivery_type)
        }
    }, [address, isShown]);
    const modifyPickupLocation = (location) => {
        return  {
            ...pickupLocationInfo,
            address: location?.address,
            lat: location?.lat,
            lng: location?.lng ? location?.lng : location?.lon ? location?.lon : location?.lng,
            zip: location?.zip,
            city: location?.city,
            state: location?.state,
            id: location?.id,
        };
    }
    const modifyCurbsideLocation = (location) => {
        return  {
            ...curbsideLocationInfo,
            address: location?.address,
            lat: location?.lat,
            lng: location?.lng ? location?.lng : location?.lon,
            zip: location?.zip,
            city: location?.city,
            state: location?.state,
            id: location?.id,
        };
    }
    const modifyShippingLocation = (location) => {
        return  {
            ...shippingLocationInfo,
            address: location?.address,
            lat: location?.lat,
            lng: location?.lng ? location?.lng : location?.lon ? location?.lon : location?.lng,
            zip: location?.zip,
            city: location?.city,
            state: location?.state,
            id: location?.id,
        };
    }
    const selectPickupLocation = (location) => {
        const data = modifyPickupLocation(location)
        if(changeAddressModal){getCart(data, deliveryType)}
        setPickupLocationInfo(data);
    }
    const selectCurbsideLocation = (location) => {
        const data = modifyCurbsideLocation(location)
        if(changeAddressModal){getCart(data, deliveryType)}
        setCurbsideLocationInfo(data);
    }
    useEffect(() => {
        if(pickupLocations && pickupLocations.length > 0 && address?.delivery_type !== "is_pickup"){
            const data = modifyPickupLocation(pickupLocations[0])
            setPickupLocationInfo(data);
        }
    }, [ pickupLocations ]);
    useEffect(() => {
        if(curbsideLocations && curbsideLocations.length > 0 && address?.delivery_type !== "is_curbside"){
            const data = modifyCurbsideLocation(curbsideLocations[0])
            setCurbsideLocationInfo(data);
        }
    }, [ curbsideLocations, address ]);
    useEffect(() => {
        if(shippingLocations && shippingLocations.length > 0 && address?.delivery_type !== "shipping"){
            const data = modifyShippingLocation(shippingLocations[0])
            setShippingLocationInfo(data);
        }
    }, [ shippingLocations, address ]);
    useEffect(() => {
        if(window.location.pathname == '/blog'){
            setBlog(true)
        }
    }, []);
    const noLinkAction = (e) => {
        e.preventDefault();
    }
    const handleOnChange = address => {
        setIsDelivery(false)
        setErrorMessage(false)
        const data = {
            ...deliveryLocation,
            address: address
        };
        setDeliveryLocation(data);
    };

    const getResultData = (Data, name) => {
        const Result = Data.address_components.filter(
            item => item.types[0] === name
        );
        if (Result.length > 0) {
            return Result[0].long_name;
        }
        return null;
    };

    const getZipCode = async result => {
        zipCode = getResultData(result, "postal_code");
        return result;
    };
    const getCart = (data, type) => {
        let data1 = {...deliveryLocation, delivery_type: type}
        isShown && dispatch(cartActions.getUnavailabilityCart(data ? {...data, delivery_type : type} : data1));
    }
    const handleOnSelect = (selectedAddress, type) => {
        if(selectedAddress && (selectedAddress.trim() !== '')) {
            const data = { ...deliveryLocation };
            setDeliveryStatus("");
            setLoadingStatus(true);
            geocodeByAddress(selectedAddress)
                .then((results) => getZipCode(results[0]))
                .then((results) => getLatLng(results))
                .then((latLng) => {
                    data.address = selectedAddress;
                    data.lat = latLng.lat;
                    data.lng = latLng.lng;
                    data.zip = zipCode;
                    setDeliveryLocation(data);
                    data.GUID = address.GUID
                    if(changeAddressModal){getCart(data, type)}
                    if(type === 'delivery'){data.delivery_type = 'delivery'}
                    locationActions.locationSelect(data).then((data) => {
                        setLoadingStatus(false);
                        if (data.status) {
                            setDeliveryStatus(data.status);
                            localStorage.setItem(
                                "deliveryStatus",
                                JSON.stringify(data.status)
                            );
                        }
                    });
                }).catch(()=>{
                setLoadingStatus(false);
                setErrorMessage(true)
            });
        }
    };

    const handleSubmit = e => {
        e.preventDefault();
        onChange(true)
        const appClass = document.getElementsByClassName("App")[0];
        if(appClass) {
            appClass.classList.remove("has-blur")
        }

        document.body.classList.remove("no-scroll")
        if(terminalId){localStorage.setItem('terminal_id', JSON.stringify(terminalId))}
        let data = {...deliveryLocation}
        if (deliveryType === 'pickup' && pickupLocations.length > 0) {
            data = {...pickupLocationInfo}
        }
        if (deliveryType === 'curbside' && curbsideLocations.length > 0) {
            data = {...curbsideLocationInfo}
        }
        if (deliveryType === 'shipping' && shippingLocations.length > 0) {
            if(address?.delivery_type !== "shipping"){
                data = {...shippingLocationInfo, address: '', delivery_type: 'shipping'}
            } else {
                data = {...shippingLocationInfo, delivery_type: 'shipping'}
            }
        }
        return dispatch(locationActions.selectedPostLocation(data, history.location.pathname.includes("checkout")))
    };

    const clearSearch = () => {
        const data = { ...deliveryLocation };
        data.address = '';
        setDeliveryLocation(data);
    };

    const handleClickOutside = () => {
        if (window.innerWidth >= 981) {
            if (!isAddress) {
                const data = { ...deliveryLocation };
                data.address = '';
                setDeliveryLocation(data);
            }
        }
    };
    const handleWrongAddress = () => {
        if (!isAddress) {
            clearSearch()
        }
    };

    const onChangeDeliveryType = (val) => {
        if(val !== deliveryType){
            changeDeliveryType(val)
            let address = deliveryLocation.address;
            if(val !== 'delivery' || val !== 'shipping'){
                setDeliveryStatus('ok');
                localStorage.setItem(
                    "deliveryStatus",
                    JSON.stringify('ok')
                );
            }
            if(val === 'curbside') {
                getCart(curbsideLocationInfo, val)
            } else if(val === 'pickup') {
                getCart(pickupLocationInfo, val)
            } else if(val === 'shipping') {
                if(!shippingLocationInfo.address && shippingLocations){
                    getCart({...shippingLocationInfo, address: shippingLocations[0].address}, val)
                } else {
                    getCart(shippingLocationInfo, val)
                }

            } else {
                if(address){
                    handleOnSelect(address, val)
                }
            }
        }
    }
    useEffect(()=>{
        if(cart){
            setLocationInfo(cart.is_store_pickup)
        }
    })
    const {modalAddressConfig} = useContext(ThemePramsContext);
    return (
        createPortal(
            <>
                {
                    blog && !changeAddressModal ?
                        <StyledModalChangeAddress isShown={isShown} themeProps={modalAddressConfig?.styling}>
                            <div className="verification" role="dialog" data-testId="verification-modal">
                                <SVGIcon name={modalAddressConfig.icon1} className="verification__logo" />
                                <div className={`verification__age`}>
                                    {modalAddressConfig?.headingText1 && <h3 className="t-shelf-heading verification__form__age-heading">WE GOTTA ASK</h3>}
                                    {modalAddressConfig.headingText2}
                                    <modalAddressConfig.button className="verification__age__button" onClick={handleSubmit}>
                                        {loadingStatus ?
                                            <>
                                                &nbsp;
                                                <LoadingEllipsis color={modalAddressConfig.color1} size=".3rem" />
                                            </>
                                            :
                                            <>
                                                Yes
                                                <span className="t-hidden">Yes button</span>
                                            </>
                                        }
                                    </modalAddressConfig.button>
                                    <a href="https://google.com" onClick={ (e) =>  ""} className={`verification__button `}>
                                        {loadingStatus ?
                                            <>
                                                &nbsp;
                                                <LoadingEllipsis color={modalAddressConfig.color1} size=".3rem" />
                                            </>
                                            :
                                            "No"
                                        }
                                    </a>
                                </div>
                            </div>
                        </StyledModalChangeAddress>
                        :
                        <StyledModalChangeAddress isShown={isShown} themeProps={modalAddressConfig?.styling}>
                            <div className="verification" role="dialog" data-testId="verification-modal">
                                {!changeAddressModal ?
                                    <>
                                        { storeType === 'rebud' ?
                                            <div className="logo-container">
                                                {modalAddressConfig.icon1 ?
                                                    <SVGIcon name={modalAddressConfig.icon1} className="verification__logo" /> :
                                                    <img src={modalAddressConfig.img1} className="verification__logo" alt="logo"/>
                                                }
                                            </div>
                                            :
                                            <div className="logo-container"><img src={brandLogo} className="verification__logo" /></div>
                                        }
                                        { modalAddressConfig.helloText && <h2 className="t-shelf-heading verification__heading">HIGH BUD,<br/>
                                            HOW ARE WE SHOPPING?</h2>}
                                    </>:
                                    <>
                                        {onChange &&
                                            <button onClick={()=>onChange(false)} type="button" className="simple-modal__button" >
                                                <SVGIcon name="close" className="simple-modal__close" />
                                                <span className="t-hidden">Close modal</span>
                                            </button>
                                        }
                                        <h3>CHANGE DELIVERY LOCATION</h3>
                                    </>

                                }


                                <div className="delivery-type-container">
                                    {shippingLocations && shippingLocations.length > 0 &&
                                        <button
                                            type="button"
                                            tabIndex="0"
                                            onClick={() => onChangeDeliveryType("shipping")}
                                            className={`delivery-time ${deliveryType === "shipping" && "active"}`}
                                        >
                                          <span className="delivery-details">
                                              <ReactSVG src="/images/icons/New-Shipping-Icon.svg" className="icon_theme" width={30}  />
                                              <div className='text-container'>
                                                  <span className={` delivery-details__copy`}>Shipping</span>
                                                  <span className='sub-text'>Out of California</span>
                                              </div>
                                          </span>
                                            <SVGIcon
                                                className="delivery-circle"
                                                name={deliveryType === "shipping" ? modalAddressConfig.icon3 : "delivery-circle"}
                                            />
                                            <Tooltip>
                                                <h2 className="t-card-sub-heading">Shipping</h2>
                                                <p>Get your order shipped
                                                    directly to your doorstep in 5-7 business days.</p>
                                            </Tooltip>
                                        </button>
                                    }
                                    
                                    {pickupLocations && pickupLocations.length > 0 &&
                                        <button
                                            type="button"
                                            tabIndex="0"
                                            onClick={() => onChangeDeliveryType("pickup")}
                                            className={`delivery-time ${deliveryType === "pickup" && "active"}`}

                                        >
                                          <span className="delivery-details">
                                              <ReactSVG src="/images/icons/pickup-icon.svg" className="icon_theme"/>
                                              <div className='text-container'>
                                                  <span className={` delivery-details__copy`}>In-Store Pickup</span>
                                                  <span className='sub-text'>Available in SoCal Only</span>
                                              </div>

                                          </span>
                                            <SVGIcon
                                                className="delivery-circle"
                                                name={deliveryType === "pickup" ? modalAddressConfig.icon3 : "delivery-circle"}
                                            />
                                            <Tooltip>
                                                <h2 className="t-card-sub-heading">In-Store Pickup</h2>
                                                <p>Stop by one of our
                                                    locations to personally
                                                    pick up your order</p>
                                            </Tooltip>
                                        </button>

                                    }
                                    {curbsideLocations && curbsideLocations.length > 0 &&
                                        <button
                                            type="button"
                                            tabIndex="0"
                                            onClick={() => onChangeDeliveryType("curbside")}
                                            className={`delivery-time ${deliveryType === "curbside" && "active"}`}
                                        >
                                          <span className="delivery-details">
                                              <ReactSVG src="/images/icons/New-Curbside-Icon.svg" className="icon_theme" width={30} />
                                              <div className='text-container'>
                                                  <span className={` delivery-details__copy`}>Curbside</span>
                                                  <span className='sub-text'>Available in SoCal Only</span>
                                              </div>
                                          </span>
                                            <SVGIcon
                                                className="delivery-circle"
                                                name={deliveryType === "curbside" ? modalAddressConfig.icon3 : "delivery-circle"}
                                            />
                                            <Tooltip>
                                                <h2 className="t-card-sub-heading">Curbside</h2>
                                                <p>Experience the ultimate convenience with Rebud's Curbside Service. Simply pull up
                                                    to one of our locations, and our dedicated budtender will hand deliver
                                                    your order right to your car, ensuring a seamless, fast and hassle- free experience.</p>
                                            </Tooltip>
                                        </button>
                                    }


                                </div>
                                <div className="verification__form">
                                    {deliveryType === "pickup" && pickupLocations && pickupLocations.length > 0 &&
                                        <div className="pickup-store">
                                            <h4>PICKUP STORE
                                            </h4>
                                            <div className="delivery-type-container mod">
                                                {pickupLocations.map(item =>
                                                        <button
                                                            tabIndex="0"
                                                            onClick={(e) => selectPickupLocation(item)}
                                                            className={`delivery-time address-modify ${item.address === pickupLocationInfo.address && "active"}`}
                                                        >

                                                            <div className="address_container">
                                                                <h3>{item.name}</h3>
                                                                <span className="delivery-details">
                                                                    <p>{item.address}</p>
                                                                </span>

                                                            </div>
                                                            <SVGIcon
                                                                className="delivery-circle"
                                                                name={item.address === pickupLocationInfo.address ? modalAddressConfig.icon3 : "delivery-circle"}
                                                            />
                                                        </button>
                                                )}
                                            </div>
                                        </div>
                                    }
                                    {deliveryType === "curbside" && curbsideLocations && curbsideLocations.length > 0 &&
                                        <div className="pickup-store">
                                            <h4>PICKUP STORE
                                            </h4>
                                            <div className="delivery-type-container mod">
                                                {curbsideLocations.map(item =>
                                                        <button
                                                            tabIndex="0"
                                                            onClick={(e) => selectCurbsideLocation(item)}
                                                            className={`delivery-time address-modify  ${item.address === curbsideLocationInfo.address && "active"}`}
                                                        >
                                                            <div className="address_container">
                                                                <h3>{item.name}</h3>
                                                                <span className="delivery-details">
                                                                    <p>{item.address}</p>
                                                                </span>
                                                            </div>
                                                            <SVGIcon
                                                                className="delivery-circle"
                                                                name={item.address === curbsideLocationInfo.address ? modalAddressConfig.icon3 : "delivery-circle"}
                                                            />
                                                        </button>
                                                )}
                                            </div>
                                        </div>
                                    }
                                    {deliveryType === "delivery" &&
                                        <label className="verification__form__label">
                                            {!changeAddressModal ? modalAddressConfig.verifyText : ''}

                                            <div className="verification__form__address-input">
                                                <LocationSearchInput
                                                    className={`verification__input ${modalAddressConfig.className2} ${isDelivery && (deliveryStatus === "notDelivery") ? "not-deliver" :""}`}
                                                    address={deliveryLocation.address || ''}
                                                    // streetSearch={true}
                                                    handleChange={handleOnChange}
                                                    handleSelect={handleOnSelect}
                                                    notDeliveryModal={isDelivery && (deliveryStatus === "notDelivery") && changeAddressModal}
                                                    enterZip={true}
                                                    errorMessage={errorMessage}
                                                    setIsAddress={setIsAddress}
                                                    onBlur={handleClickOutside}
                                                    handleWrongAddress={handleWrongAddress}
                                                    placeholder="Start typing address and select from list"
                                                    notDelivery={isDelivery && (deliveryStatus === "notDelivery") && !changeAddressModal }
                                                />
                                                {deliveryLocation.address &&
                                                    <button  className="verification-delivery_address__clear" onClick={clearSearch}>
                                                        <ReactSVG src="/images/icons/clear-modal.svg"/>
                                                    </button>
                                                }
                                            </div>
                                        </label>
                                    }
                                    {deliveryType === "shipping" &&
                                        <h3 className="t-shelf-heading verification__form__age-heading">NO THC OR CANNABIS PRODUCTS <br/>
                                            CAN BE SHIPPED</h3>
                                    }


                                    {isKiosk &&
                                        <div className="verification__form__ip-address-input">
                                            <div className={`t-sub-${modalAddressConfig.className1} verification__form__label-text`}>Please add POS generated ID</div>
                                            <InputAuthorization
                                                inputName="terminalId"
                                                value={terminalId}
                                                placeholder="ex. ASD12345"
                                                type="text"
                                                className="terminal-id"
                                                onChange={(e) => setTerminalId(e.target.value.trim())}
                                                required={false}
                                            />
                                        </div>
                                    }
                                    {isDelivery && (deliveryStatus === "notDelivery") && changeAddressModal  &&
                                        <div>
                                            <div className="bg-text">
                                                <SVGIcon className="svg-right" name="filter-svg-left" />
                                                <p className="bg-text-main">
                                                    <ReactSVG  src="/images/icons/warning-icon.svg" className="warning-icon"/>
                                                    {shippingLocations && shippingLocations.length > 0 ?
                                                        'Delivery is unavailable in your state, please choose the shipping method to shop now':
                                                        'Delivery is unavailable in your state'
                                                    }
                                                    </p>
                                                <SVGIcon className="svg-left" name="filter-svg-right" />

                                            </div>
                                            <p>
                                                Feel free to search to for a different
                                                address or you can keep your current  delivery location
                                            </p>
                                        </div>
                                    }
                                    {changeAddressModal &&
                                        <div>
                                            {cart && cart.cartItems && cart.cartItems.length > 0 && deliveryStatus !== "notDelivery" &&
                                                <>
                                                    <div className="bg-text">
                                                        <SVGIcon className="svg-right" name="filter-svg-left" />
                                                        <p className="bg-text-main">
                                                            <ReactSVG  src="/images/icons/warning-icon.svg" className="warning-icon"/>
                                                            Unavailable Items at New Location</p>
                                                        <SVGIcon className="svg-left" name="filter-svg-right" />
                                                    </div>
                                                    <ul className="list">

                                                        {cart.cartItems.map(item =>
                                                            <CartItem
                                                                key={item.id}
                                                                name={item.product && item.product.name}
                                                                category={item.product && item.product.categories}
                                                                netWeight={item.product && item.product.net_weight}
                                                                netWeight_g={item.product && item.product.net_weight_g}
                                                                imgUrl={item.product && item?.product?.pictures[0]}
                                                            />
                                                        )}

                                                    </ul>
                                                    <p className="items-disable">
                                                        Items will be removed from your bag upon update
                                                    </p>
                                                </>}

                                            {history.location.pathname.includes("checkout") &&
                                                <>
                                                    <div className="bg-text">
                                                        <SVGIcon className="svg-right" name="filter-svg-left" />
                                                        <p className="bg-text-main">
                                                            Head Back to Your Bag?</p>
                                                        <SVGIcon className="svg-left" name="filter-svg-right" />
                                                    </div>
                                                    <p>By making this update you’ll go back to review your new order details</p>
                                                </>
                                            }
                                        </div>
                                    }

                                    {
                                        !changeAddressModal ?  <div className={`verification__age ${deliveryType !== 'pickup' && deliveryType !== 'curbside'  && deliveryType !== 'shipping' && (!deliveryStatus || !deliveryLocation.address.trim()) ? "is-disabled" : ""}`}>
                                            {modalAddressConfig?.headingText1 && <h3 className="t-shelf-heading verification__form__age-heading">WE GOTTA ASK</h3>}
                                            {modalAddressConfig.headingText2}
                                            <div className={modalAddressConfig.className3}>
                                                <modalAddressConfig.button
                                                    onClick={handleSubmit}
                                                    disabled={deliveryType !== 'pickup'  && deliveryType !== 'shipping' && deliveryType !== 'curbside' && (!deliveryStatus || !deliveryLocation.address.trim() || !isAddress)}>
                                                    {loadingStatus ?
                                                        <>
                                                            &nbsp;
                                                            <LoadingEllipsis color={modalAddressConfig.color1} size=".3rem" />
                                                        </>
                                                        :
                                                        <>
                                                            Yes
                                                            <span className="t-hidden">Yes button</span>
                                                        </>
                                                    }
                                                </modalAddressConfig.button>
                                                <a href="https://google.com" onClick={ (e) => (deliveryType !== 'pickup' && (!deliveryStatus || !deliveryLocation.address.trim())) ? noLinkAction(e) : ""}
                                                   className={`verification__button ${deliveryType !== 'pickup' && deliveryType !== 'curbside' && (!deliveryStatus || !deliveryLocation.address.trim()) ? "is-disabled" : ""}`}>
                                                    {loadingStatus ?
                                                        <>
                                                            &nbsp;
                                                            <LoadingEllipsis color={modalAddressConfig.color1} size=".3rem" />
                                                        </>
                                                        :
                                                        "No"
                                                    }
                                                </a>
                                            </div>
                                        </div>
                                        // : <ButtonOutline className="update_button" onClick={handleSubmit} disabled={!deliveryStatus || deliveryStatus === "notDelivery" || !deliveryLocation.address.trim() || !isAddress}>
                                        : <ButtonOutline className="update_button" onClick={handleSubmit} disabled={deliveryType !== 'pickup' && deliveryType !== 'shipping'  && deliveryType !== 'curbside' && (!deliveryStatus || !deliveryLocation.address.trim() || !isAddress || deliveryStatus === "notDelivery")}>
                                                {loadingStatus ?
                                                    <>
                                                        &nbsp;
                                                        <LoadingEllipsis color={modalAddressConfig.color1} size=".3rem" />
                                                    </>
                                                    :
                                                    <>
                                                        UPDATE
                                                        <span className="t-hidden">Update button</span>
                                                    </>
                                                }
                                            </ButtonOutline>
                                    }
                                </div>
                            </div>

                        </StyledModalChangeAddress>
                }
            </>
            ,
        document.body
)

    )
};
const mapStateToProps = ({ shippingLocations, userAddress, getUnavailableCart, getGiveAway, locationInfo, pickupLocations, curbsideLocations }) => ({
    address: userAddress.address,
    cart: getUnavailableCart.cart,
    giveAway: getGiveAway.giveAway,
    locationPhone: locationInfo.locationPhone,
    pickupLocations: pickupLocations.pickupLocations,
    curbsideLocations: curbsideLocations.curbsideLocations,
    shippingLocations: shippingLocations.shippingLocations,
});

VerifyModalAddress.propTypes = {
    className: PropTypes.string,
    isShown: PropTypes.bool,
    address: addressPT,
};

export default connect(mapStateToProps)(VerifyModalAddress);
